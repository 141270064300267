@import "../../../assets/styles/variables.module.scss";

.main {
  position: relative;
  overflow: hidden;
}

.main_top {
  display: flex;
  align-items: center;
  justify-content: space-between;
  border-bottom-style: solid;
  border-bottom-width: 1px;
  border-bottom-color: $horizontal-line-color;
  padding-bottom: 2rem;
  position: relative;

  & img {
    cursor: pointer;
    position: absolute;
    right: 0rem;
  }

  & span {
    font-size: 1.8rem;
    font-weight: 600;
  }
}

