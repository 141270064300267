@import "../../assets/styles/variables.module.scss";

.form {
  margin-top: 2rem;
  padding-right: 2rem;
  display: grid;
  grid-template-columns: 1fr 1fr;
  grid-template-rows: repeat(10, min-content);
  column-gap: 2rem;
  row-gap: 2rem;
  overflow-y: auto;
  height: 70vh;

  & label {
    font-size: 1.4rem;
  }
}

.twocolumns {
  grid-column: 1/3;
}

.buttons {
  grid-column: 1/3;
  display: flex;
  gap: 1rem;
  justify-content: flex-end;
}

.button {
  width: 150px;
}
