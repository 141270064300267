@import '../../../assets/styles/variables.module.scss';
@import '../../../assets/styles/mixins.module.scss';

.container {
    position : absolute;
    height: 91.5%;
    width: 100%;
    z-index: 1000;
    background-color: $input_background; // yuval test
    overflow-x: auto;
    overflow-y: auto
}

.table {
    @include table-default;

}

.table_data {

    & tr:hover {
        background-color: #e4e1e1;
    }

    & td {
        padding: 1.5rem 2rem;
        border-style: solid;
        border-color: rgb(211, 211, 211);
        border-width: 1px;
    }


}
