@import '../../../assets/styles/variables.module.scss';

.container {
    text-transform: none;
    position: relative;

    &:hover {
        .customer {
            &_icons {
                visibility: visible;
            }
        }
    }

    &:not(:first-child), &:not(:nth-child(2)) {
        
        &:last-child, &:nth-last-child(2) {
            .history {
                top: -20rem !important;
            }
        }
    }

}

.history {
    position: absolute;
    z-index: 100;
    right: 32rem;
    top: 8rem;

}

.email {

    text-align: justify;
}

.customer {
    display: flex;
    justify-content: space-between;
    gap: 0.5rem;
    &_icons {
        display: flex;
        gap: 0.5rem;
        visibility: hidden;

        & img {
            width: 20px;
            cursor: pointer;
        }
    }
}

.website_container {
    display: flex;
    gap: 1rem
}

.website {
    display: flex;
    justify-content: center;
    align-items: center;
    text-align: center;
    padding: 0.5rem 0.5rem;
    border-style: solid;
    border-width: 1px;
    border-color: $blue-primary;
    width: 35px;
    border-top-left-radius: 5px;
    border-top-right-radius: 5px;
    border-bottom-left-radius: 5px;
    border-bottom-right-radius: 5px;
}

.money {
    display: flex;
    flex-direction: column;
    min-width: 100px;
}

.not_payed {
    display: flex;
    gap: 1rem;
    justify-content: space-between;
}

.actions {
    display: flex;
    flex-direction: column;
    gap: 1rem;

    & img {
        width: 20px;
        cursor: pointer;
    }
}

.transcation {
    font-weight: 600;
    color: $blue-primary;
    cursor: pointer;
}

.forbid {
    cursor: not-allowed !important;
}