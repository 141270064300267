@import '../../assets/styles/variables.module.scss';
@import '../../assets/styles/mixins.module.scss';

.container {
    @include page-container-default
}

.buttons {
    display: flex;
    justify-content: space-between;
    gap: 2rem
}

.buttons_right {
    width: 550px;
    display: flex;
    gap: 1rem;
}

.main {
    width: 100%;
    height: 100%;
    display: flex;
    flex-direction: column;
    margin-bottom: 2rem;
    background-color: white;
    border-top-left-radius: 5px;
    border-top-right-radius: 5px;
    border-bottom-left-radius: 5px;
    border-bottom-right-radius: 5px;
    position: relative;
    padding: 2rem 2rem;
}

.main_top{
    display: flex;
    justify-content: space-between;
    align-items: center;
    @include gray-bottom-border;
}

.main_title {
    @include page-title
}

.main_middle {
    display: grid;
    grid-template-columns: 1fr 1fr;
    grid-template-rows: 1fr 1fr;
    gap: 2rem;
    padding-top: 2rem;
    @include gray-bottom-border;
    padding-bottom: 0 !important; // reset value from gray-bottom-border
}


.search_container {
    grid-row: 1/2;
    grid-column: 1/2;
    width: 30%;
    display: flex;
    gap: 1rem;
    padding: 0.5rem 1rem;
    background-color: $background-color-all-pages;
    color: #878787;

    & input {
        border-style: none;
        outline: none;
        background-color: $background-color-all-pages;
        color: #878787;
        font-size: 1.4rem;
    }
}

.amountselect {
    justify-self: end;
    grid-row: 1/2;
    grid-column: 2/3;
}


.website_details_container {
    grid-row: 2/3;
    grid-column: 1/3;
    display: flex;
    justify-content: space-between;
    color: $blue-primary;
    font-size: 1.4rem;
}


.table_data {

    & tr:hover {
        background-color: #e4e1e1;
    }

    & td {
        padding: 1.5rem 2rem;
        border-style: solid;
        border-color: rgb(211, 211, 211);
        border-width: 1px;

    }

    & tr:last-child td div{
        top: -15rem ;
    }

    & tr:nth-last-child(2) td div {
        top: -15rem;
    }

    & tr:nth-last-child(3) td div {
        top: -15rem;
    }

    & tr:first-child td div {
        top: 5rem !important;
    }

    & tr:nth-child(2) td div {
        top: 5rem !important;
    }

    & tr:nth-child(3) td div {
        top: 5rem !important;
    }
    

}

.main_bottom {
    padding-top: 1rem;
    display: flex;
    justify-content: space-between;
    width: 100%;
}
