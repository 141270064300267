@import url('https://fonts.googleapis.com/css2?family=Poppins:wght@100;200;300;400;500&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Rubik:wght@300;400;500&display=swap');

*{
  margin: 0;
  padding: 0;
  box-sizing: border-box;
  
}

html {
  font-size: 62.5%;
  font-family: 'Poppins', 'Rubik', sans-serif;
  overflow: hidden;
}


.app {
  width: 100vw;
  height: 100vh;
  background-color: white;
  display: grid;
  grid-template-rows: 73px 1fr;
  grid-template-columns: min-content 1fr;
  overflow: hidden;
}

input {
  font-family: 'Poppins', 'Rubik', sans-serif;
}

select {
  font-family: 'Poppins', 'Rubik', sans-serif;
}

option {
  font-family: 'Poppins', 'Rubik', sans-serif;
}

button {
  font-family: 'Poppins', 'Rubik', sans-serif;
}

input[type="date"]::-webkit-calendar-picker-indicator {
  cursor: pointer;
  border-radius: 4px;
  margin-right: 2px;
  opacity: 0.6;
  filter: invert(0.4);
}

@media screen and (max-width: 500px) {
  
  .app {
    grid-template-columns: 1fr;
    grid-template-rows: max-content 1fr;
  }

}