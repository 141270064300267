.main {
    display: flex;
    flex-direction: column;

}

.textcontent {
    margin-top: 2rem;
    font-size: 1.5rem;
    text-align: right;
    padding-right: 2rem;
    overflow-y: auto;
    & ul {
        height: 200px;

        & li {
            margin-top: 1rem;
        }
    }

}

.button {
    margin-top: 1rem;
    align-self: center;
}

.list_item {
    display: flex;
    flex-direction: column;
}
