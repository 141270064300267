@import '../../assets/styles/variables.module.scss';

.container {
    position: absolute;
    top: 5rem;
    right: 0rem;
    z-index: 100;
    background-color: white;
    filter: drop-shadow(0px 4px 4px rgba(0, 0, 0, 0.25));
    display: flex;
    flex-direction: column;
    gap: 1rem;
    padding: 1rem 1rem;


    & span {
        border-bottom-style: solid;
        border-bottom-width: 1px;
        border-bottom-color: $horizontal-line-color;
        cursor: pointer;
    }

    & textarea {
        resize: none;
        width: 200px;
        height: 100px;
        border-style: none;
        outline: none;
        font-size: inherit;
        font-family: inherit;
    }

}