@import "../../assets/styles/variables.module.scss";

.container {
  width: 75vw;
  position: relative;
  overflow: hidden;
}

.main_top {
  display: flex;
  align-items: center;
  justify-content: space-between;
  border-bottom-style: solid;
  border-bottom-width: 1px;
  border-bottom-color: $horizontal-line-color;
  padding-bottom: 2rem;

  & img {
    cursor: pointer;
  }

  & span {
    font-size: 1.8rem;
    font-weight: 600;
  }
}

.table_data {
  & tr:hover {
    background-color: #e4e1e1;
  }

  & td {
    padding: 1.5rem 2rem;
    border-style: solid;
    border-color: rgb(211, 211, 211);
    border-width: 1px;
  }
}

.paid {
  color: $green-primary;
}

.notpaid {
  color: $red-primary;
}

.options {
  display: flex;
  justify-content: center;
  gap: 1rem;

  & img {
    cursor: pointer;
  }
}

.buttons {
  margin-top: 2rem;
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: flex-end;
  gap: 4rem;
}

.cancel {
  font-size: 1.4rem;
  color: $red-primary;
  font-weight: 400;
  cursor: pointer;
}

.reset {
  font-size: 1.4rem;
  color: $red-primary;
  font-weight: 400;
  cursor: pointer;
}

.customButton {
  min-width: 157px;
}
