@import '../../../assets/styles/variables.module.scss';

.container {
    width: 100%;
    height: 100%;
    z-index: 200;
    position: absolute;
    display: flex;
    justify-content: center;
    align-items: center;
    overflow: hidden;
}


.background {
    width: 100%;
    height: 100%;
    position: absolute;
    background-color: $seperator_background;
    z-index: 200;
}

.main {
    background-color: white;
    min-height: 380px;
    min-width: 558px;
    z-index: 300;
    padding: 3rem 4rem;
    z-index: 3000;
}

@media screen and (max-width: 500px) {

    .container {
        height: 100vh;
    }

    .main {
        min-width: unset;
    }
    
}

@media screen and (max-width: 900px) and (max-height: 500px) {
    .main {
        overflow-y: scroll;
    }
}   
