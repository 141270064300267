@import '../../assets/styles/variables.module.scss';
@import '../../assets/styles/mixins.module.scss';

.container {
    @include page-container-default;
}

.revenue {
    height: 60px;
    width: 100%;
    background-color: rgb(219,223,231);
    filter: drop-shadow(0px 4px 4px rgba(0, 0, 0, 0.25));
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding-left: 2rem;
    padding-right: 2rem;

}

.revenue_title {
    font-size: 1.8rem;
}

.revenue_values {
    display: flex;
    gap: 4rem;
    color: $blue-primary;
    font-size: 2rem;
    font-weight: 600;
}

.main {
    width: 100%;
    height: 100%;
    display: flex;
    flex-direction: column;
    background-color: white;
    padding: 2rem 2rem;
    position: relative;
    margin-bottom: 2rem;
}

.main_top {
    display: flex;
    justify-content: space-between;
    align-items: center;
    width: 100%;
    @include gray-bottom-border;
}

.main_title {
    @include page-title
}

.main_buttons {
    display: flex;
    gap: 1rem
}

.main_middle {
    margin-top: 2rem;
    display: flex;
    justify-content: space-between;
    @include gray-bottom-border;
}

.filter {
    display: flex;
    align-items: center;
    gap: 1rem;
    font-size: 1.4rem;
    font-weight: 400;
    color: $blue-primary;
    background-color: $input_background;
    padding: 1rem 1.5rem;
    border-top-left-radius: 5px;
    border-top-right-radius: 5px;
    border-bottom-right-radius: 5px;
    border-bottom-left-radius: 5px;
    cursor: pointer;
}


.table_data {

    & tr:hover {
        background-color: #e4e1e1;
    }

    & td {
        padding: 1.5rem 2rem;
        border-style: solid;
        border-color: rgb(211, 211, 211);
        border-width: 1px;

    }
}

.main_bottom {
    padding-top: 1rem;
    display: flex;
    justify-content: space-between;
    width: 100%;
}

.mobile_pagination {
    display: flex;
    justify-content: center;
    margin-bottom: 1rem;
    margin-top: 1rem;
}


//mobile
@media screen and (max-width: 500px) {
    .container {
        width: 100vw;
    }

    .revenue {
        height: 150px;
        flex-direction: column;
        justify-content: center;
        align-items: center;
        gap: 2rem;
    }

    .main_middle {
        margin-top: 1rem;
    }
}

@media screen and (max-width: 400px) {

    .container {
        padding-left: 1rem;
        padding-right: 1rem;
    }

    .revenue_title {
        font-size: 1.6rem;
    }
    
    .revenue_values {
        font-size: 1.6rem;
    }

    .main_buttons {
        gap: 0.5rem;
    }
}

@media screen and (max-width: 900px) and (max-height: 500px) {
    .main {
        height: 120vh;
    }
}