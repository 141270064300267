@import '../../../assets/styles/variables.module.scss';

.container {
    width: 100%;
    min-height: 90px;
    display: flex;
    padding: 1rem 1rem; 
    justify-content: space-between;
    align-items: center;

    &:hover {
        background-color: $input_background;
    }
}

.general {
    display: flex;
    align-items: center;
    gap: 1rem;
}

.total {
    font-size: 1.8rem;
}

.subitem {
    width: 280px;
    font-size: 1.4rem;
    display: grid;
    grid-template-rows: 1fr;
    grid-template-columns: 1fr 75px 70px;

    & span {
        text-align: right;
    }
}

