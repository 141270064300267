@import '../../../assets/styles/variables.module.scss';

.main {
    display: flex;
    flex-direction: column;
    gap: 8rem;
}

.data {
    display: grid;
    grid-template-columns: 1fr 1fr;
    grid-template-rows: 1fr 1fr 1fr;
    gap: 1rem;
    margin: 2rem 0rem;
}

.data_item {
    font-size: 1.6rem;
    display: flex;
    gap: 1rem;
}

.buttons {
    margin-top: 1rem;
    width: 100%;
    display: flex;
    justify-content: flex-end;
    gap: 2rem;
  }
  
  .cancel {
    font-size: 1.4rem;
    color: $red-primary;
    font-weight: 400;
    cursor: pointer;
  }
  
  .customButton {
    width: 157px;
  }
  
  .denied {
      color: red;
      font-weight: 500;
  }

  .approved {
      color: green;
      font-weight: 500;
  }