@import "../../assets/styles/variables.module.scss";

.status {
  display: flex;
  gap: 1rem;
  font-size: 1.3rem;
  font-weight: 400;
  color: black;
  justify-content: center;
}

.retainer {
  text-align: center;
}

.email {
  text-transform: none;
}

.line_status {
  display: flex;
  justify-content: center;
  border-top-left-radius: 5px;
  border-top-right-radius: 5px;
  border-bottom-left-radius: 5px;
  border-bottom-right-radius: 5px;
  color: black;
  font-size: 1.4rem;
  padding: 0.5rem 0rem;
}

.waiting {
  background-color: #ebd403;
  cursor: pointer;
}

.approved {
  background-color: $green-primary;
  color: white;
}

.note {
  max-width: 120px;
  word-wrap: break-word;
  color: $blue-primary;
  text-transform: none;
  text-align: right;
  font-size: 1.4rem;
}

.delete {
  & img {
    cursor: pointer;
  }
}
