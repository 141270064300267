@import '../../assets/styles/variables.module.scss';

.container {
    max-width: 480px;
    height: 400px;
    position: relative;

}

.main_top {
    display: flex;
    align-items: center;
    justify-content: center;
    border-bottom-style: solid;
    border-bottom-width: 1px;
    border-bottom-color: $horizontal-line-color;
    padding-bottom: 2rem;
    position: relative;

    & img {
        cursor: pointer;
        position: absolute;
        right: 0rem;
    }

    & span {
        font-size: 1.8rem;
        font-weight: 600;
    }

}

.textcontent {
    margin-top: 2rem;
    font-size: 1.4rem;
    text-align: right;
    height: 250px;
    overflow-y: auto;
    padding-right: 2rem;
}

.button {
    position: absolute;
    bottom: 0rem;
    left: 0rem;
    right: 0rem;
    margin-left: 10rem;
    margin-right: 10rem;
}