@import '../../../assets/styles/variables.module.scss';

.container {
    width: 100%;
    max-height: 100px;
    background-color: $input_background;
    overflow-y: auto;
    position: absolute;
    top: 5rem;
    z-index: 1000;
    padding-left: 4rem;
    left: 0;
}

.item {
    margin: 1rem 0rem;
    font-size: 1.6rem;
    cursor: pointer;
    color: $gray-primary;
}