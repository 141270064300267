@import '../../../assets/styles/variables.module.scss';

.container {
    width: 310px;
    height: max-content;
    background-color: white;
    border-top-left-radius: 5px;
    border-top-right-radius: 5px;
    border-bottom-left-radius: 5px;
    border-bottom-right-radius: 5px;
    display: flex;
    flex-direction: column;
    padding: 1rem 2rem;
    color: $blue-primary;
}

.top {
    font-size: 1.6rem;
    font-weight: 600;
    border-bottom-style: solid;
    border-bottom-width: 1px;
    border-bottom-color: $horizontal-line-color;
    padding-bottom: 1rem;

    & a {
        text-decoration: none;
        color: $blue-primary;
    }
}

.middle {
    margin-top: 2rem;
    display: flex;
    flex-direction: column;
    gap: 1rem;
    border-bottom-style: solid;
    border-bottom-width: 1px;
    border-bottom-color: $horizontal-line-color;
    padding-bottom: 2rem;
}

.middle_item {
    display: flex;
    align-items: center;
    font-weight: 500;
    font-size: 1.6rem;
    gap: 1.5rem;
}

.bottom {
    font-size: 1.4rem;
    font-weight: 400;
    color: $gray-primary;
    margin-top: 1rem;
}

@media screen and (max-width: 500px) {
    .container {
        width: 100%;
    }
}