@import '../../assets/styles/variables.module.scss';

.main {
    height: 400px;
}

.main_top {
    display: flex;
    align-items: center;
    justify-content: space-between;
    border-bottom-style: solid;
    border-bottom-width: 1px;
    border-bottom-color: $horizontal-line-color;
    padding-bottom: 2rem;

    & img {
        cursor: pointer;
    }

    & span {
        font-size: 1.8rem;
        font-weight: 600;
    }

}

.total {
    font-size: 2.5rem;
    border-bottom-style: solid;
    border-bottom-width: 1px;
    border-bottom-color: $horizontal-line-color;
    margin: 0.5rem 0rem;
}

.data {
    margin-top: 1rem;
    display: flex;
    flex-direction: column;
    height: 300px;
    overflow-y: scroll;
    gap: 1rem;
}