@import '../../../assets/styles/variables.module.scss';
@import '../../../assets/styles/mixins.module.scss';

.container {
    overflow-y: auto;
    overflow-x: auto;
    height: 100%;
    max-height: 70vh;
    min-height: 450px;
    background-color: $input_background; 
}

.table {
    @include table-default;
}

// The Following Media quries are fixing table height shrinking
// @media screen and (max-height: 960px) {
//     .container {
//         height: $table-max-height-960;
//     }
// }

// @media screen and (max-height: 910px) {
//     .container {
//         height: $table-max-height-910;
//     }
// }

// @media screen and (max-height: 860px) {
//     .container {
//         height: $table-max-height-860;
//     }
// }

// @media screen and (max-height: 810px) {
//     .container {
//         height: $table-max-height-810;
//     }
// }

// @media screen and (max-height: 760px) {
//     .container {
//         height: $table-max-height-760;
//     }
// }

// @media screen and (max-height: 710px) {
//     .container {
//         height: $table-max-height-710;
//     }
// }

// @media screen and (max-height: 660px) {
//     .container {
//         height: $table-max-height-660;
//     }
// }

// @media screen and (max-height: 610px) {
//     .container {
//         height: $table-max-height-610;
//     }
// }

// @media screen and (max-height: 560px) {
//     .container {
//         height: $table-max-height-560;
//     }
// }

//mobile
@media screen and (max-width: 500px) {

    .container {
        width: unset;
        overflow-x: scroll;
        min-height: 250px;
        margin-bottom: 0rem;
    }

}

@media screen and (max-width: 900px) and (max-height: 500px) {
    .container {
        height: 900px;
    }
}