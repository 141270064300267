@import "../../assets/styles/variables.module.scss";

.main_middle {
  margin-top: 2rem;
  display: flex;
  flex-direction: column;
  gap: 2rem;
  max-height: 300px;

  & table {
    font-size: 1.6rem;

    & td {
      padding: 1.5rem 2rem;
    }
  }
}

.center {
  text-align: center;
}
