@import '../../../assets/styles/variables.module.scss';

.container {
    background-color: $background-color-all-pages;
    display: flex;
    gap: 1rem;
    align-items: center;
    height: 50px;
    padding-left: 2rem;
    padding-right: 2rem;
    border-style: solid;
    border-width: 2px;
    border-top-left-radius: 5px;
    border-top-right-radius: 5px;
    border-bottom-right-radius: 5px;
    border-bottom-left-radius: 5px;
    border-color: $background-color-all-pages;

    & input {
        width: 100%;
        border-style: none;
        outline: none;
        background-color: $background-color-all-pages;
        font-size: 1.4rem;
        color: #878787
    }
}

.icon {
    display: flex;
}

.blue {
    border-color: $blue-primary !important;
    & input {
        color: $blue-primary !important;
    }
}

.red {
    border-color: $red-primary !important;
    & input {
        color: $red-primary !important;
    }
}

.green {
    border-color: $green-primary !important;
   & input {
        color: $green-primary !important;
   }
}