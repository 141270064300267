.container {
    
    display: flex;
    gap: 1.5rem;

    & select {
        font-size: 1.6rem;
    }

    & span {
        font-size: 1.4rem;
        color: #878787;
        align-self: center;
    }
}