@import "../../../assets/styles/variables.module.scss";

.container {
  position: absolute;
  width: fit-content;
  background-color: white;
  left: 0;
  right: 0;
  margin-left: auto;
  margin-right: auto;
  top: 27.5%;
  border-style: solid;
  border-width: 1px;
  border-color: $red-primary;
  border-top-left-radius: 5px;
  border-top-right-radius: 5px;
  border-bottom-left-radius: 5px;
  border-bottom-right-radius: 5px;
  padding: 3rem 3rem;
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 5rem;
  z-index: 15000;
}

.title {
  font-size: 2rem;
  color: $red-primary;
}

.buttons {
  display: flex;
  gap: 2rem;
}
