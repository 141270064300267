.container {
    width: 100%;
    min-height: 400px;
    min-width: 200px;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    position: relative;

    img {
        max-width: 50vw;
        max-height: 70vh;
        cursor: pointer;
        z-index: 20000;
    }

    iframe {
        z-index: 20000;
    }
}

.exit {
    position: absolute;
    right: -3.5rem;
    top: -2.5rem;
    cursor: pointer;
}

.loading {
    position: absolute;
}

.pdf {
    overflow-y: auto;
    max-height: 500px;
    margin: 2rem 0rem;
    max-width: 70vw;
}

.money {
    align-self: flex-end;
    display: flex;
    flex-direction: column;
    text-align: right;
    font-size: 1.6rem;
}

