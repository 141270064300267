@import '../../../../assets/styles/variables.module.scss';
@import '../../../../assets/styles/mixins.module.scss';

.container {
    z-index: 1000;
    background-color: rgb(252, 252, 252);
    width: 300px;
    height: 200px;
    display: flex;
    flex-direction: column;
    align-items: center;
    gap: 1rem;
    border-style: solid;
    border-width: 1px;
    border-top-left-radius: 5px;
    border-top-right-radius: 5px;
    border-bottom-left-radius: 5px;
    border-bottom-right-radius: 5px;

}


.title {
    width: 90%;
    text-align: center;
    border-bottom-style: solid;
    border-width: 1px;
    padding: 1rem 0rem;
}

.data {
    display: flex;
    flex-direction: column;
    gap: 0.5rem;
    width: 100%;
    padding: 0rem 5rem;
    overflow-y: auto;
}

.data_item {
    width: 100%;
    display: flex;
    justify-content: space-between;
}