@import '../../assets/styles/variables.module.scss';

.container {
    width: 100%;
    text-align: center;
    font-size: 1.4rem;
    padding: 1rem 2rem;
    border-style: solid;
    border-width: 1px;
    border-top-left-radius: 5px;
    border-top-right-radius: 5px;
    border-bottom-right-radius: 5px;
    border-bottom-left-radius: 5px;
    cursor: pointer;
    transition: all 0.3s;

}

.default {
    &:hover {
        color: white;
    }
}


.blue {
    border-color: $blue-primary;
    color: $blue-primary;

    &:hover {
        background-color: $blue-primary;
    }
}

.green {
    border-color: $green-primary;
    color: $green-primary;

    &:hover {
        background-color: $green-primary;
    }
}

.red {
    border-color: $red-primary;
    color: $red-primary;

    &:hover {
        background-color: $red-primary;
    }
}

.orange {
    border-color: orange;
    color: orange;

    &:hover {
        background-color: orange;
    }
}

.reverse {
    color: white !important;

    &_blue {
        background-color: $blue-primary;
        box-shadow: 0px 8px 12px rgba(25, 39, 101, 0.3);
    }

    &_red {
        background-color: $red-primary;
        box-shadow: 0px 8px 12px rgba(172, 0, 45, 0.3)
    }

    &_green {
        background-color: $green-primary;
        box-shadow: 0px 8px 12px rgba(1, 95, 22, 0.3)
    }

    &_orange {
        background-color: orange;
        box-shadow: 0px 8px 12px rgba(252, 174, 5, 0.705)
    }
}

.disabled {
    color: white !important;
    background-color: gray;
    border-color: gray !important;
    cursor: not-allowed !important;

}

@media screen and (max-width: 500px) {

    .container {
        width: unset;
    }
    
}


