@import '../../assets/styles/variables.module.scss';

.main {
    min-height: 400px;
    position: relative;
}

.main_top {
    display: flex;
    align-items: center;
    justify-content: center;
    border-bottom-style: solid;
    border-bottom-width: 1px;
    border-bottom-color: $horizontal-line-color;
    padding-bottom: 2rem;

    & img {
        cursor: pointer;
        position: absolute;
        right: 0rem;
    }

    & span {
        font-size: 1.8rem;
        font-weight: 600;
    }

}

.totals {
    display: flex;
    flex-direction: column;
    text-align: right;
    font-size: 1.6rem;
    margin-bottom: 2rem;
    padding-bottom: 2rem;
    border-bottom-style: solid;
    border-bottom-width: 1px;
    border-bottom-color: $horizontal-line-color;
}

.note {
    margin-top: 1rem;
    text-align: center;
    font-size: 1.4rem;
    color: $blue-primary;
}

.draganddrop {
    height: 350px;
}

.image_container { 
    height: 350px;
    width: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
    cursor: pointer;

    & img {
        width: 100px;
    }
}

.checkanimation {
    position: absolute;
    margin-left: auto;
    margin-right: auto;
    left: 0;
    right: 0;
    bottom: 45%;
}

.submit {
    width: 180px;
    position: absolute;
    margin-left: auto;
    margin-right: auto;
    left: 0;
    right: 0;
    bottom: 0;
    text-align: center;
}

.success_msg {
    position: absolute;
    margin-left: auto;
    margin-right: auto;
    left: 0;
    right: 0;
    bottom: 8rem;
    text-align: center;
    color: green;
    font-size: 2rem;
}

@media screen and (max-width: 900px) and (max-height: 500px) {
    .main {
        min-height: unset;
        height: 70vh;
        overflow-y: scroll;
    }

    .totals { 
        margin-top: 2rem;
    }

    .image_container {
        height: 230px;
    }

    .checkanimation {
        position: absolute;
        margin-left: auto;
        margin-right: auto;
        left: 0;
        right: 0;
        bottom: 40%;
        height: min-content;
    }

    .submit {
        bottom: -2rem;
        z-index: 1000;
        padding-bottom: 2rem;
    }
}